export const getDateByTime = (time: string) => {
    const [hours, minutes] = time.split(':').map((t) => parseInt(t, 10));
    const date = new Date().setHours(hours, minutes ?? 0);

    return date;
};

export const isNowBetweenDates = (start: string, end: string) => {
    if (!start || !end) return false;

    const startDate = getDateByTime(start);
    const endDate = getDateByTime(end);

    const now = new Date().getTime();

    return startDate <= now && now <= endDate;
};

export const evaluateDate = (dateToEval: unknown) => {
    if (typeof dateToEval === 'string' || typeof dateToEval === 'number') {
        const date = new Date(dateToEval);

        if (!isNaN(date.getTime())) return date;
    }

    if (typeof dateToEval === 'object' && dateToEval instanceof Date) return dateToEval;

    return undefined;
};

export const storeWillOpen = (start: string) => {
    if (!start) return false;
    const startDate = getDateByTime(start);
    const now = new Date().getTime();

    return now < startDate;
};
