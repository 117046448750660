import { evaluateDate } from '~/shared/utils/date-utils';
import { NodeLocalStorage } from './node-storage';
import { NodeStorage } from './types';

export enum NodeStorageKey {
    LAST_UPDATED = 'lastUpdated',
    FRAME = 'frame',
    TRANSLATIONS = 'translations',
}

// SafeNodeStorage extends NodeLocalStorage with safeties implemented like try/catch statements and fallbacks.
export class SafeNodeStorage extends NodeLocalStorage {
    private defaultLifespan = 30;

    constructor(private storage: NodeStorage) {
        super(storage);

        this.get = this.get.bind(this);
        this.getIfAvailable = this.getIfAvailable.bind(this);
        this.set = this.set.bind(this);
        this.invalidate = this.invalidate.bind(this);
    }

    get<DataType>(key: NodeStorageKey) {
        try {
            const storedData = this.storage.getItem(key);

            if (storedData) {
                const parsed = JSON.parse(storedData);

                return parsed as DataType;
            }
        } catch (error) {
            console.error(`Stored data in key (${key}) does not exist or cannot be parsed.`, error);
        }

        return null;
    }

    async getIfAvailable<DataType>(
        key: NodeStorageKey,
        options: {
            fallback?: DataType | (() => Promise<DataType>);
            lifespanInMinutes?: number;
        },
    ): Promise<DataType | null> {
        const { lifespanInMinutes = this.defaultLifespan, fallback } = options;

        const dataFromStorage = this.get<DataType>(key);
        const isUpdateNeeded = this.shouldUpdateKey(key, { lifespanInMinutes });

        if ((!dataFromStorage || isUpdateNeeded) && fallback) {
            try {
                if (fallback instanceof Function) {
                    const updatedData = await fallback();
                    this.set(key, updatedData);

                    return updatedData;
                }

                return fallback;
            } catch (error) {
                console.error(`Error calling fallback for storage data ('${key}')`, error);

                return null;
            }
        }

        return dataFromStorage;
    }

    set(key: NodeStorageKey, value: unknown) {
        this.storage.setItem(key, JSON.stringify(value));

        if (key !== NodeStorageKey.LAST_UPDATED) this.setLastKeyUpdate(key);
    }

    shouldUpdateKey(key: NodeStorageKey, options: { lifespanInMinutes?: number }) {
        const { lifespanInMinutes = this.defaultLifespan } = options;

        const dates = this.get<Record<string, number>>(NodeStorageKey.LAST_UPDATED);
        const lastUpdated = evaluateDate(dates?.[key]);

        /**
         * We will change the last updated date if:
         * 1. There's no record of last updated date.
         * 2. If age of record is longer than indicated lifespan in minutes.
         */
        return (
            !lastUpdated || (Date.now() - lastUpdated.getTime()) / (1000 * 60) > lifespanInMinutes
        );
    }

    setLastKeyUpdate(key: NodeStorageKey, date?: string | number | Date) {
        const dates = this.get<Record<string, number>>(NodeStorageKey.LAST_UPDATED);
        const hasRecords = typeof dates === 'object' && dates !== null;

        const dateToSet = typeof date !== 'undefined' ? evaluateDate(date) : Date.now();

        this.set(NodeStorageKey.LAST_UPDATED, {
            ...(hasRecords && dates),
            [key]: dateToSet,
        });
    }

    invalidate(key?: Exclude<NodeStorageKey, 'lastUpdated'>) {
        if (key) {
            try {
                // Setting last update to 0 will set its date to 01-01-1970 which will require it to revalidate.
                this.setLastKeyUpdate(key, 0);
            } catch (error) {
                console.error(`${key} cannot be invalidated.`);
            }
        } else {
            // If no key is passed, we will invalidate all of the keys by emptying lastUpdated.
            this.set(NodeStorageKey.LAST_UPDATED, {});
        }
    }
}
