import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Page } from '~/lib';
import { Frame } from '~/shared/utils';
import { _getBasePrice, _getGTMAffiliation, _getTotalValue, _transformGAEvent } from './helper';
import {
    GTM_EVENT,
    GTMProductEventModel,
    GTMBasketItemModel,
    PushToLayerModel,
    GTMOverlaySearchModel,
} from './models';
import { WishListEventData } from '../models';

export const useGTMEvents = (frame?: Frame | null) => {
    function pushToDataLayer(item: PushToLayerModel, maxWait = 5000) {
        return new Promise<void>((resolve) => {
            console.debug('pushToDataLayer', item);
            const dataLayer = (window as any).dataLayer || [];
            if (!Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer = dataLayer;
            }

            const done = () => {
                // If given item has own eventCallback fire it also
                resolve();
            };

            // If GTM hasn't loaded at all (ie. adblock), fire callback immediately
            if (!(window as any).google_tag_manager || !frame?.settings?.googleTagManagerId) {
                done();
            }

            // Fail safe: If GTM eventCallback doesn't fire before maxWait time we
            // trigger the callback anyway
            setTimeout(done, maxWait);
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                ...item,
                eventCallback: done,
            });
        });
    }

    // GTM Checkout event step 1
    function gtmViewCart(items: GTMProductEventModel[], value: string) {
        pushToDataLayer({
            event: GTM_EVENT.VIEW_CART,
            ecommerce: {
                currency: frame?.market.currency?.currency ?? '',
                items,
                value,
            },
        });
    }

    // GTM Checkout event step 2
    function gtmBeginCheckout(items: GTMProductEventModel[], value: string, coupon: string) {
        console.debug('gtmBeginCheckout', items, value, coupon);

        pushToDataLayer({
            event: GTM_EVENT.BEGIN_CHECKOUT,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                value,
            },
        });
    }

    // GTM Checkout event step 3
    function gtmAddShippingInfo(
        items: GTMProductEventModel[],
        value: string,
        coupon: string,
        shipping_tier: string,
    ) {
        pushToDataLayer({
            event: GTM_EVENT.ADD_SHIPPING_INFO,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                shipping_tier,
                value,
            },
        });
    }

    // GTM Checkout event step 4
    function gtmAddPaymentInfo(
        items: GTMProductEventModel[],
        value: string,
        coupon: string,
        payment_type: string | undefined,
    ) {
        pushToDataLayer({
            event: GTM_EVENT.ADD_PAYMENT_INFO,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                payment_type,
                value,
            },
        });
    }

    // GTM Add coupon event
    function gtmAddCouponCode(items: GTMProductEventModel[], value: string, coupon: string) {
        pushToDataLayer({
            event: GTM_EVENT.ADD_COUPON,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                value,
            },
        });
    }

    // GTM Add coupon event
    function gtmRemoveCouponCode(items: GTMProductEventModel[], value: string, coupon: string) {
        pushToDataLayer({
            event: GTM_EVENT.REMOVE_COUPON,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                value,
            },
        });
    }

    // GTM Add gift card event
    function gtmAddGiftCard(items: GTMProductEventModel[], value: string, coupon: string) {
        pushToDataLayer({
            event: GTM_EVENT.ADD_GIFT_CARD,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                value,
            },
        });
    }

    // GTM Remove gift card event
    function gtmRemoveGiftCard(items: GTMProductEventModel[], value: string, coupon: string) {
        pushToDataLayer({
            event: GTM_EVENT.REMOVE_GIFT_CARD,
            ecommerce: {
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items,
                value,
            },
        });
    }

    // GTM Purchase event
    function gtmPurchase(
        affiliationOptions: {
            storeId: string | undefined;
            storeName: string | undefined;
            isLoggedIn: boolean | undefined;
            isStorePersonnel: boolean | undefined;
            paymentMethod: string | undefined;
        },
        coupon: string,
        transaction_id: string,
        items: GTMProductEventModel[],
        value: number,
        shipping: number,
    ) {
        const affiliation = _getGTMAffiliation(affiliationOptions);
        const itemsWithAffiliation = items.map((item) => ({
            ...item,
            affiliation,
        }));

        pushToDataLayer({
            event: GTM_EVENT.PURCHASE,
            ecommerce: {
                transaction_affiliation: affiliation,
                coupon,
                currency: frame?.market.currency?.currency ?? '',
                items: itemsWithAffiliation,
                shipping,
                transaction_id,
                value,
            },
        });
    }

    // GTM Remove from cart event
    function gtmRemoveFromCart(
        items: GTMProductEventModel[],
        quantity = 1,
        basketID?: string,
        basketContent?: GTMBasketItemModel[],
    ) {
        const value = _getBasePrice(items) * quantity;
        pushToDataLayer({
            event: GTM_EVENT.REMOVE_FROM_CART,
            basketID,
            basketContent,
            ecommerce: {
                currency: frame?.market.currency?.currency,
                value,
                items,
            },
        });
    }

    type AddToCartOptionsType = {
        items: GTMProductEventModel[];
        quantity?: number;
        basketContent?: GTMBasketItemModel[];
        basketID?: string;
    };

    // GTM add to cart event
    function gtmAddToCart({ items, quantity = 1, basketContent, basketID }: AddToCartOptionsType) {
        const value = _getBasePrice(items) * quantity;
        pushToDataLayer({
            event: GTM_EVENT.ADD_TO_CART,
            basketID,
            basketContent,
            ecommerce: {
                currency: frame?.market.currency?.currency,
                value,
                items,
            },
        });
    }

    // GTM view item event
    function gtmViewItem(items: GTMProductEventModel[], breadcrumb: string) {
        pushToDataLayer({
            event: GTM_EVENT.VIEW_ITEM,
            breadcrumb,
            ecommerce: {
                currency: frame?.market.currency?.currency,
                value: _getTotalValue(items),
                items,
            },
        });
    }

    function gtmReservation(items: GTMProductEventModel[], storeId?: string) {
        pushToDataLayer({
            event: GTM_EVENT.PRODUCT_RESERVATION_COMPLETE,
            product: {
                currency: frame?.market.currency?.currency,
                value: _getTotalValue(items),
                shop_id: storeId,
                items,
            },
        });
    }

    // GTM select item event
    function gtmSelectItem(items: GTMProductEventModel[], raptorModule?: string) {
        pushToDataLayer({
            event: GTM_EVENT.SELECT_ITEM,
            ...(raptorModule && { raptorModule }),
            ecommerce: {
                currency: frame?.market.currency?.currency,
                items,
            },
        });
    }

    // GTM item view list vvent
    async function gtmProductListView(items: GTMProductEventModel[]) {
        pushToDataLayer({
            event: GTM_EVENT.VIEW_ITEM_LIST,
            ecommerce: {
                items,
            },
        });
    }

    // GTM overlay search
    function gtmOverlaySearch(params: GTMOverlaySearchModel) {
        pushToDataLayer({
            event: 'SearchOverlayResults',
            SearchOverlayMeasurement: params,
        });
    }

    type gtmInitGPageProps = {
        customerId: string | undefined;
        page: Page;
        loggedIn: boolean;
    };

    function gtmInitGPage({ customerId, page, loggedIn }: gtmInitGPageProps) {
        const pageData = {
            event: 'pageview',
            userID: customerId,
            countrySite: page.market.toUpperCase(),
            loggedIn: loggedIn.toString(),
            environment: frame?.settings?.googleTagManagerEnvironment || '',
            pageCategory: page.type,
            // The type of device being used by the user
            device: isMobile ? 'mobile' : 'desktop',
            // The hostname of the page
            hostname: window.location.hostname,
            // First url when opened the site
            originalLocation: sessionStorage.getItem('originalLocation'),
            pagePath: window.location.pathname + window.location.search,
        };
        pushToDataLayer(pageData);
    }

    function gaFilter(eventAction: string, eventLabel: string) {
        pushToDataLayer(_transformGAEvent('Filter', eventAction, eventLabel));
    }

    function gaProductPage(eventAction: string, eventLabel: string) {
        pushToDataLayer(_transformGAEvent('ProductPage', eventAction, eventLabel));
    }

    function gaStorePickup(eventAction: string, eventLabel: string) {
        pushToDataLayer(_transformGAEvent('Reserver i butik', eventAction, eventLabel));
    }

    function gaInternalSearch(eventLabel: string) {
        pushToDataLayer(_transformGAEvent('Search', 'Search Results - Zero Results', eventLabel));
    }

    function gaAuthenticationEvents(eventAction: string, eventLabel: string | undefined) {
        pushToDataLayer(_transformGAEvent('Login', eventAction, eventLabel ?? '/'));
    }

    function gaWishlistEvents({
        eventAction,
        eventLabel = '',
        productId,
        brand,
        name,
    }: WishListEventData) {
        const gaWishListData = {
            event: 'gaEvent',
            eventCategory: 'Wishlist',
            eventAction,
            eventLabel,
            productId,
            brandId: brand,
            productName: name,
        };
        pushToDataLayer(gaWishListData);
    }

    return useMemo(() => {
        return {
            gtmPurchase,
            gtmViewCart,
            gtmBeginCheckout,
            gtmAddShippingInfo,
            gtmAddPaymentInfo,
            gtmAddCouponCode,
            gtmRemoveCouponCode,
            gtmAddGiftCard,
            gtmRemoveGiftCard,
            gtmRemoveFromCart,
            gtmAddToCart,
            gtmViewItem,
            gtmReservation,
            gtmSelectItem,
            gtmProductListView,
            gtmInitGPage,
            gaFilter,
            gaProductPage,
            gaStorePickup,
            gaInternalSearch,
            gaAuthenticationEvents,
            gaWishlistEvents,
            gtmOverlaySearch,
        };
    }, [frame]);
};
