import { NodeStorage, StorageType } from './types';

export class NodeLocalStorage implements StorageType {
    private _available: boolean;

    constructor(private ls: NodeStorage) {
        this._available = true;
    }

    set(key: string, value: any) {
        this._available && this.ls.setItem(key, value);
    }

    get(key: string) {
        return this._available ? this.ls.getItem(key) : undefined;
    }

    delete(key: string) {
        this._available && this.ls.removeItem(key);
    }
}
