export enum GTM_EVENT {
    ADD_PAYMENT_INFO = 'add_payment_info',
    ADD_SHIPPING_INFO = 'add_shipping_info',
    ADD_TO_CART = 'add_to_cart',
    BEGIN_CHECKOUT = 'begin_checkout',
    VIEW_ITEM_LIST = 'view_item_list',
    PURCHASE = 'purchase',
    REMOVE_FROM_CART = 'remove_from_cart',
    SELECT_ITEM = 'select_item',
    VIEW_CART = 'view_cart',
    VIEW_ITEM = 'view_item',
    ADD_COUPON = 'add_coupon',
    REMOVE_COUPON = 'remove_coupon',
    ADD_GIFT_CARD = 'add_gift_card',
    REMOVE_GIFT_CARD = 'remove_gift_card',
    OVERLAY_SEARCH_RESULTS = 'overlay_search_results',
    PRODUCT_RESERVATION_COMPLETE = 'product_reservation_complete',
}

export enum GTM_LIST_ID {
    PRODUCT_LIST = 'ProductList',
    ARTICLE_PRODUCTS = 'ArticleProducts',
    SEARCH_RESULTS = 'SearchResults',
    PRODUCT_CAROUSEL = 'ProductCarousel',
    COMPLEMENTARY_PRODUCTS = 'ComplementaryProducts',
    RAPTOR = 'Raptor',
}

export enum GTM_LIST_NAME {
    ARTICLE_LIST = 'ArticleList',
    COMPLEMENTARY = 'Complementary',
    CAROUSEL = 'Carousel',
    RAPTOR = 'Raptor',
}

export type GTMProductEventModel = {
    index?: number;
    item_brand: string;
    item_category: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_color: string;
    item_freight_group: string;
    item_id: string;
    item_name: string;
    price: number;
    item_product_id: string;
    item_rating?: string;
    item_ratingCount?: number;
    item_ratingValue?: number;
    item_serie: string;
    item_variant: string;
    item_warehouse_type: string;
    quantity?: number;
    stockStatus?: boolean;
};

export type GTMEcommerceEventModel = {
    affiliation?: string;
    coupon?: string;
    currency?: string;
    discount?: string;
    item_list_id?: string;
    item_list_name?: string;
    items: GTMProductEventModel[];
    payment_type?: string;
    shipping_tier?: string;
    shipping?: string;
    tax?: string;
    transaction_affiliation?: string;
    transaction_id?: string;
    value?: string;
};

export type GTMEventModel = {
    event: GTM_EVENT;
    ecommerce: GTMEcommerceEventModel;
};

export type GTMBasketItemModel = {
    item: string;
    quantity: string;
    price: string;
    unique_id: string;
};

export type GTMOverlaySearchModel = {
    search_term: string;
    number_of_results?: number;
    search_url: string;
    destination_url?: string;
    type_of_result?:
        | 'Product'
        | 'Search suggestion'
        | 'See all products'
        | 'Popular searches'
        | 'Other shortcuts';
    product_id?: number;
    product_name?: string;
    product_ean?: number;
    product_brand?: string;
    product_full_name?: string;
};

export type PushToLayerModel =
    | ({
          eventCallback?: () => void;
      } & {
          [key: string]: any;
      })
    | GTMEventModel;
