import { CustomStorage } from './types';

export class SessionStorage implements CustomStorage {
    private _available: boolean;

    constructor() {
        this._available = false;
        if (typeof window === 'undefined') {
            console.warn('SessionStorage is not avaliable on the current environment.');
            return;
        }
        this._available = true;
    }

    set(key: string, value: any) {
        this._available && window.sessionStorage.setItem(key, value);
    }

    get(key: string) {
        return this._available ? window.sessionStorage.getItem(key) : undefined;
    }

    delete(key: string) {
        this._available && window.sessionStorage.removeItem(key);
    }
}
